import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-woo-commerce-success-modal',
  templateUrl: './woo-commerce-success-modal.component.html',
  styleUrls: ['./woo-commerce-success-modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class WooCommerceSuccessModalComponent extends LocalizedComponent {
  private _modal = inject(MatDialog);

  closeModal(): void {
    this._modal.closeAll();
  }

  public items = [
    {
      icon: 'assets/img/woo-commerce/woo-commerce-1.svg',
      text: this.trans('STORES.EXPECTED_STEP_1'),
    },
    {
      icon: 'assets/img/woo-commerce/woo-commerce-2.svg',
      text: this.trans('STORES.EXPECTED_STEP_2'),
    },
    {
      icon: 'assets/img/woo-commerce/woo-commerce-3.svg',
      text: this.trans('STORES.EXPECTED_STEP_3'),
    },
    {
      icon: 'assets/img/woo-commerce/woo-commerce-4.svg',
      text: this.trans('STORES.EXPECTED_STEP_4'),
    },
  ];
}
