<div class="modal-container">
  <button class="close-btn" (click)="closeModal()">
    <span>&times;</span>
  </button>

  <div class="dialog-header">
    <h2 class="dialog-title">{{ trans('STORES.LINK_STORE') }}</h2>
    <p class="dialog-description">{{ trans('STORES.LINK_STORE_DESCRIPTION') }}</p>
  </div>

  <form [formGroup]="storeForm" class="dialog-body" (ngSubmit)="submitStoreURL()">
    <label for="store-url">{{ trans('STORES.STORE_URL') }}</label>
    <input
      type="text"
      id="store-url"
      placeholder="{{ trans('STORES.STORE_URL_PLACEHOLDER') }}"
      formControlName="storeUrl"
    />

    <button id="store-url-btn" type="submit">{{ trans('STORES.LINK_STORE') }}</button>
  </form>
</div>
