import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LocalizedComponent } from 'app/presentation/base/localized.component';

@Component({
  selector: 'app-woo-commerce-failure-modal',
  templateUrl: './woo-commerce-failure-modal.component.html',
  styleUrls: ['./woo-commerce-failure-modal.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class WooCommerceFailureModalComponent extends LocalizedComponent {
  private _modal = inject(MatDialog);

  closeModal(): void {
    this._modal.closeAll();
  }
}
