<div class="modal-container">
  <div class="modal-header">
    <img
      src="assets/img/woo-commerce/store-linked-failure.svg"
      alt="Store Linked Unsuccessfully"
      class="modal-img"
    />
    <h2 class="modal-title">{{ trans('STORES.ADDED_FAILED') }}</h2>

    <p class="modal-description">{{ trans('STORES.ADDED_FAILED_MESSAGE') }}</p>
  </div>

  <div class="modal-footer">
    <button (click)="closeModal()">{{ trans('STORES.OKAY') }}</button>
  </div>
</div>
