import { Component, Inject, inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-youcan-link-video',
  standalone: true,
  templateUrl: './youcan-link-video.component.html',
  styleUrls: ['./youcan-link-video.component.scss'],
})
export class YoucanLinkVideoComponent {
  public dialog = inject(MatDialog);

  public url: SafeUrl;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { url: string },
    public sanitizer: DomSanitizer,
  ) {
    this.url = sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  closeVideoDialog(): void {
    this.dialog.closeAll();
  }
}
