<div class="modal-container">
  <div class="modal-header">
    <img
      src="assets/img/woo-commerce/store-linked-success.svg"
      alt="Store Linked Successfully"
      class="modal-img"
    />
    <h2 class="modal-title">{{ trans('STORES.STORE_ADDED') }}</h2>
  </div>

  <div class="modal-body">
    <p class="modal-subtitle">{{ trans('STORES.STORE_EXPECTED') }}</p>

    <ul>
      <li *ngFor="let item of items">
        <div class="modal-icon">
          <img [src]="item.icon" [alt]="item.text" />
        </div>
        <p>{{ item.text }}</p>
      </li>
    </ul>
  </div>

  <div class="modal-footer">
    <button (click)="closeModal()">{{ trans('STORES.OKAY') }}</button>
  </div>
</div>
