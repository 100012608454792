/* eslint-disable import/extensions */
import { country } from '@/v3/features/country/data';
import { user } from '@/v3/features/user/data';
import { Component, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { environment } from 'environment';

@Component({
  selector: 'app-woo-commerce-modal',
  standalone: true,
  templateUrl: './woo-commerce-modal.component.html',
  styleUrls: ['./woo-commerce-modal.component.scss'],
  imports: [FormsModule, ReactiveFormsModule],
})
export class WooCommerceModalComponent extends LocalizedComponent {
  storeForm: FormGroup;

  storeUrl: string;

  countryCode: string;

  private _modal = inject(MatDialog);

  constructor(private fb: FormBuilder, private route: ActivatedRoute) {
    super();
    this.storeForm = this.fb.group({
      storeUrl: ['', [Validators.required, Validators.pattern('https?://.+')]],
    });
  }

  closeModal(): void {
    this._modal.closeAll();
  }

  submitStoreURL(): void {
    if (this.storeForm.valid) {
      this.storeUrl = this.storeForm.value.storeUrl;

      let fullUrl = `${this.storeUrl}/wc-auth/v1/authorize`;

      const appName = 'Taager';
      const scope = 'read_write';
      const userId = user.id;
      const returnUrl = environment.WOO_COMMERCE_RETURN_URL.replace('{country}', country.code);
      const callbackUrl = environment.WOO_COMMERCE_CALLBACK_URL.replace('{country}', country.code);

      const params = new URLSearchParams({
        app_name: appName,
        scope,
        user_id: String(userId),
        return_url: returnUrl,
        callback_url: callbackUrl,
      });

      fullUrl = `${fullUrl}?${params.toString()}`;

      window.location.href = fullUrl;

      this.closeModal();
    } else {
      throw new Error("Form isn't valid");
    }
  }
}
